var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-0 overflow-y-auto qms_settings_page",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticStyle: {
      "max-width": "1200px"
    },
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-h6"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-briefcase")]), _vm._v("Job Settings ")], 1), _c('v-card-text', [_c('v-list', {
    staticClass: "transparent"
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Job Types")]), _c('v-list-item-subtitle', [_c('span', {
    staticClass: "text-caption font-italic"
  }, [_vm._v("eg. Machined EVA, 3D Printed, Supplies")])])], 1), _c('v-list-item-action', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click() {
        _vm.$refs.jobTypes.open();
      }
    }
  }, [_vm._v("Manage")]), _c('JobTypes', {
    ref: "jobTypes"
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }