<template>
  <div>

    <v-dialog :value='show' max-width='400px' scrollable persistent>
      <v-card :disabled='loading'>
        <v-card-title class='primary white--text text-body-1'>
          Job Types
          <v-spacer />
          <v-btn fab x-small text dark @click='addItem'><v-icon>mdi-plus</v-icon></v-btn>
        </v-card-title>
        <v-card-text style='height: 40vh;' class='px-0'>
          <v-list dense class='' ref='sortable'>
            <v-list-item v-for='item in items' :key='item.xid' class='' :data-id='item.xid'>
              <v-list-item-avatar class='dragMe my-0'><v-icon>mdi-drag</v-icon></v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{item.name}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class='my-0'>
                <v-menu>
                  <template #activator='{on,attrs}'>
                    <v-btn v-bind="attrs" v-on="on" fab x-small text><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                  </template>
                    <v-list dense>
                      <v-list-item link @click='rename(item)'>
                        <v-icon class='primary--text mr-4'>mdi-rename-box</v-icon>Rename
                      </v-list-item>
                      <v-divider />
                      <v-list-item link @click='remove(item)'>
                        <v-icon class='red--text mr-4'>mdi-trash-can</v-icon>Delete
                      </v-list-item>
                    </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer/>
          <v-btn class='primary' @click='show=false' :loading='loading'>Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
.dragMe{
  cursor: grab;
}
</style>

<script>
import { mapActions } from 'vuex'
import Sortable from 'sortablejs';
export default {
  name: '',

  components: {
  },

  data: () => ({
    show: false,
    loading: false,
    items: [],
    sortable: null,
  }),

  computed: {
  },

  methods:{
    ...mapActions("API", ["API"]),
    ...mapActions("UI", ["PROMPT", "CONFIRM"]),
    ...mapActions("PRINTING", ["PRINT"]),

    open(){
      this.refreshList();
      this.show = true;
      this.$nextTick(this.makeSortable);
    },

    refreshList(){
      this.loading = true;
      this.API({ method: 'GET', endpoint: 'job_types' })
      .then(items => { this.items = items; })
      .finally(() => { this.loading = false; });
    },

    addItem(){
      this.PROMPT({ title: "Add Type", message: "Job Type:" })
      .then(name => {
        if(name === '') return;
        this.API({ method: 'PUT', endpoint: 'job_types', data: { name } })
        .then(this.refreshList)
      });
    },

    makeSortable(){
      if(this.sortable) return;
      this.sortable = new Sortable(this.$refs.sortable.$el, {
        animation: 250,
        handle: '.dragMe',
        swapThreshold: 1,
        onEnd: this.sort,
      });
    },

    sort(e){
      if(e.newIndex == e.oldIndex) return;
      let sort_order = this.sortable.toArray();
      this.loading = true;
      this.API({ method: 'POST', endpoint: 'job_types/sort', data: { sort_order } })
      .finally(() => { this.loading = false; })
    },

    rename(item){
      this.PROMPT({ title: "Rename Job Type", message: `Rename '${item.name}':`, placeholder: item.name })
      .then(name => {
        if(name === '' || name === item.name) return;
        this.loading = true;
        this.API({ method: 'PATCH', endpoint: `job_types/${item.xid}`, data: { name } })
        .finally(this.refreshList);
      });
    },

    remove(item){
      this.CONFIRM({ title: 'Delete Job Type', message: `Delete '${item.name}'?` })
      .then(() => {
        this.API({ method: 'DELETE', endpoint: `job_types/${item.xid}` })
        .finally(this.refreshList);
      });
    },

  },

  mounted() {
  },

};
</script>
