<template>
  <v-container fluid class='pt-0 overflow-y-auto qms_settings_page'>

    <v-card style='max-width:1200px;' :disabled='loading' :loading='loading'>
      <v-card-title class='primary white--text text-h6'>
        <v-icon class='mr-2' dark>mdi-briefcase</v-icon>Job Settings
      </v-card-title>
      <v-card-text>
        <v-list class='transparent'>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Job Types</v-list-item-title>
              <v-list-item-subtitle>
                <!--Description of setting here.<br />-->
                <span class='text-caption font-italic'>eg. Machined EVA, 3D Printed, Supplies</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn class='primary' @click='()=>{$refs.jobTypes.open()}'>Manage</v-btn>
              <JobTypes ref='jobTypes' />
            </v-list-item-action>
          </v-list-item>

        </v-list>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import JobTypes from '@/components/Settings/JobTypes'
//import { mapActions } from 'vuex'
export default {
  name: 'JobSettings',

  components: {
    JobTypes
  },

  data: () => ({
  }),

  computed: {
  },

  methods:{
  },

  mounted() {
  },

};
</script>
